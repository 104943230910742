import ListItem from "./ListItem";
import React, { useState, useRef, useEffect } from 'react';

const ToDoList = () => {

    const itemsRef = useRef([]);
    itemsRef.current = [];

    const [clickedEl, updateClickedEl] = useState('');

    const [listItems, updateListItems] = useState([
        {
            id: 1,
            order: 1,
            text: 'Question 1',
            readonly: true,
        },
        {
            id: 2,
            order: 2,
            text: 'Question 2',
            readonly: true,
        },
        {
            id: 3,
            order: 3,
            text: 'Question 3',
            readonly: true,
        },
    ]);

    const [newItem, updateNewItem] = useState('');

    const updateInput = (e) => {
        updateNewItem(e.target.value);
    }

    const updateListText = (elId, e) => {
        updateListItems(listItems.map((itemKey) => {
            if(elId === itemKey.id){
                itemKey.text = e.target.value;
            }
            return itemKey;
        }));
    }

    const removeItem = (elId) => {
        updateListItems(listItems.filter((itemKey) => {
            if(itemKey.id === elId){
                return null;
            } else{
                return itemKey;
            }
        }))
    }

    const addItem = () => {
        const itemObj = {
            id: listItems.length + 1,
            order: listItems.length + 1,
            text: newItem,
            readonly: true,
        };
        if(newItem !== ''){
            updateListItems(listItems => [...listItems, itemObj]);
            updateNewItem('');
        }
    }

    const handleEdit = (elId) => {
        updateListItems(listItems.map( (itemKey, i) => {
            if(itemKey.id === elId){
                itemKey.readonly =  false;
            }
            if(!itemKey.readonly){
                updateClickedEl(i);
            }
            return itemKey;
        }));
    }

    const sortFunc = (a, b) => {
        if(a.order > b.order){
            return 1;
        } else{
            return -1;
        }
    }

    const moveUp = (elId, prevId) => {
        updateListItems(listItems.map( (itemKey) => {
            if(elId !== 1){
                if(itemKey.order === elId){
                    itemKey.order -= 1;
                } else if(itemKey.order === prevId){
                    itemKey.order += 1;
                }
            }
            return itemKey;
        }));
    }

    const moveDown = (elId, nextId) => {
        updateListItems(listItems.map( (itemKey) => {
            if(elId !== listItems.length){
                if(itemKey.order === elId){
                    itemKey.order += 1;
                } else if(itemKey.order === nextId){
                    itemKey.order -= 1;
                }
            }
            return itemKey;
        }));
    }
    

    const updateRef = (el, i) => {
        itemsRef.current[i] = el;
    }

    useEffect(() => {
        // console.log(clickedEl);
        function handleClickOutside(e){
            if(itemsRef.current && !itemsRef.current[clickedEl].contains(e.target)){
                console.log('Clicked outside of me');
                updateListItems(listItems.sort(sortFunc).map( (itemKey, i) => {
                    if(i === clickedEl){
                        itemKey.readonly = true;
                    }
                    return itemKey;
                }));
            }
        }
        if(clickedEl !== ''){
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }; 
        }
         
    });

    return ( 
        <div className="todolist">
            <h2 className="title">Example of ToDo list using React</h2>
            <div className={`todolist__empty ${(listItems.length < 1) ? null : 'hidden'}`}>
                <span className="todolist__text">There is no ToDo's to do 😂</span>
            </div>
            <div className="todolist__box">
                {
                    listItems.sort(sortFunc).map( (listItemKey, i) => (
                        <ListItem 
                            ref={el => updateRef(el, i)}
                            key={listItemKey.id}
                            listItems={listItemKey}
                            removeItem={() => removeItem(listItemKey.id)}
                            handleEdit={() => handleEdit(listItemKey.id)}
                            moveDown={() => moveDown(listItemKey.order, listItemKey.order+1)}
                            moveUp={() => moveUp(listItemKey.order, listItemKey.order-1)}
                            updateListText={updateListText}
                            refItem={itemsRef[i]} />
                    ))
                }
                
            </div>
            <div className="todolist__btns flex">
                <input 
                    type="text" 
                    name="adding-field" 
                    className="todolist__input" 
                    placeholder="Please enter text of yout ToDo"
                    value={newItem}
                    onChange={(e) => updateInput(e)} />
                <button className="todolist__addbtn" onClick={() => addItem()}>Add new listitem</button>
            </div>
        </div>
     );
}
 
export default ToDoList;