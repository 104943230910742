import React from 'react';

import upload from './img/upload.png';
import edit from './img/edit.png';
import close from './img/close.png';

const ListItem = React.forwardRef(
    ({listItems, removeItem, handleEdit, moveDown, moveUp, updateListText}, ref) => {
    
    // useEffect(() => {
    //     function handleClickOutside(e){
    //         if(refItem.current && !refItem.current.contains(e.target)){
    //             console.log('Clicked outside of me');
    //         }
    //     }
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };  
    // }, [refItem]);

    return ( 
        <div className={`todolist__item flex ${(listItems.length < 1) ? 'hidden' : null}`}>
            <input 
                ref={ref}
                className={`todolist__text ${listItems.readonly ? '' : 'edit'}`} 
                type="text" 
                value={listItems.text} 
                readOnly={listItems.readonly}
                onChange={(e) => updateListText(listItems.id, e)} />
            <div className="todolist__tools flex">
                <div className="todolist__tool todolist__up" onClick={moveUp}>
                    <img src={upload} alt="" />
                </div>
                <div className="todolist__tool todolist__down" onClick={moveDown}>
                    <img src={upload} alt="" />
                </div>
                <div className="todolist__tool todolist__edit" onClick={handleEdit}>
                    <img src={edit} alt="" />
                </div>
                <div className="todolist__tool todolist__remove" onClick={removeItem}>
                    <img src={close} alt="" />
                </div>
            </div>
        </div>
     );
});
 
export default ListItem;